import React from 'react'
import Header from '../components/header'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Spacer from '../components/spacer'

export default function PrivacyPolicy() {
	return (
		<Layout>
			<SEO title='Privacy Policy: SIERA Group’s Commitment to Your Privacy' description='Privacy Policy: Read Sierra Group’s Privacy Policy to understand how we protect your data and ensure transparency in our practices.' />
			<Header />
			<Spacer />
			<h1 className='hide'>Privacy Policy: Protecting Your Information with Care</h1>
			<div className='h1  max-1400 ma p40'>Privacy Policy</div>
			<Spacer />
			<Spacer className='m-hide' />
			<Spacer className='m-hide' />
			<Spacer className='m-hide' />
			<div className='max-1400 ma p40'>
				<div className='max-900'>
					<p className='m0 op-50'>
						Thank you for visiting our website www.sieragroup.com.au. This website is owned and operated by Siera Group Pty Ltd(ABN 31 162 709 097).By accessing and/or using this website and related services, you agree to these Terms and Conditions, which include our Privacy Policy.You should review our Privacy Policy and these Terms carefully and immediately cease using our website if you do not agree to these Terms
						<br />
						<br />
						In these Terms,'us', 'we' and 'our' means Siera Group Pty Ltd and any of its subsidiaries or affiliated entities, or Social Medias (Facebook, LinkedIn, Instagram and YouTube).
						<br />
						<br />
						We may ask you to confirm that you have your parent's or guardian's permission and that they have agreed to these Terms on your behalf, and, even if we do not do this, we will assume that this is the case and will provide access to our website and your account on this basis
					</p>
					<br />
					<br />
					<p className='h4 m0'>Collection Notice</p>
					<br />
					<p className='m0 op-50'>
						We are committed to safeguarding personal privacy. We collect personal information about you in order to respond to your enquiry, provide you with necessary information, as well keep you up to date on our business and for purposes otherwise set out in our Privacy Policy.
						<br />
						<br />
						We may disclose that information to third parties that help us deliver our services (including information technology suppliers, communication suppliers and our business partners) or as required by law. If you do not provide this information, we may not be able to provide all of our services to you. We may also disclose your personal information to recipients that are located outside of Australia
					</p>
					<br />
					<br />
					<p className='h4 m0'>Our Privacy Policy explains</p>
					<br />
					<p className='m0 op-50'>
						(i) How we store and use, and how you may access and correct your personal information;
						<br />
						<br />
						(ii) How you can lodge a complaint regarding the handling of your personal information; and
						<br />
						<br />
						(iii)How we will handle any complaint. If you would like any further information about our privacy policies or practices, please contact us at admin@sieragroup.com.au.Please allow 30 days for this request to be processed.
						<br />
						<br />
						By providing your personal information to us, you consent to the collection, use, storage and disclosure of that information as described in the Privacy Policy and these Terms.
						<br />
						<br />
						We will only collect or monitor any personal information about you with your consent including as provided in this Privacy Policy or if it is otherwise lawful to do so. The only personal information collected by us is what has been provided to us or to third parties in accordance with this PrivacyPolicy.
					</p>

					<br />
					<br />
					<p className='h4 m0'>Personal information that may be requested includes</p>
					<br />
					<p className='m0 op-50'>
						(i) Contact information such as your full name, date of birth, mobile telephonenumber, current and valid email address, residential address and postcode;
						<br />
						<br />
						(ii) More detailed contact preferences, information regarding your professio nalinterests and information about your experience with our products or services or third party products or services listed on aWebsite. This information is requested in order to tailor our subsequent communications to you and continuouslyimprove our products and services;
						<br />
						<br />
						(iii) our credit card details (where you purchase any products or services from us);and
						<br />
						<br />
						(iv) The contact details of third parties. If you are asked to provide details about otherpeople, please ensure that these individuals are happy for their details to begiven to us and used for the purposes set out in this policy (which may includeusing their details for marketing purposes)
					</p>
					<br />
					<br />
					<p className='h4 m0'>How we collect this information</p>
					<p className='m0 op-50'>
						{' '}
						<br />
						We may collect these types of personal information either directly from you, or from third parties.
						<br />
						<br />
						We may collect this information when you
						<br />
						<br />
						(i) Register on our website or app;
						<br />
						<br />
						(ii) Communicate with us through correspondence, chats, email, or when you share information with us from other social applications, services or websites;
						<br />
						<br />
						(iii) Interact with our sites, services, content and advertising; or
						<br />
						<br />
						(iv) Invest in our business or enquire as to a potential purchase in our business.
						<br />
						<br />
						In addition, when you apply for a job or position with us we may collect certain information from you (including your name, contact details, working history and relevant records checks) from any recruitment consultant, your previous employers and others who may be able to provide information to us to assist in our decision on whether or not to make you an offer of employment or engage you under a contract. This Privacy Policy does not apply to acts and practices in relation to employee records of our current and former employees, which are exempt from the Privacy Act.
					</p>
					<br />
					<br />
					<p className='h4 m0'>Personal information collected will be used for the following primary purposes</p>

					<br />
					<p className='m0 op-50'>
						(i) For the purposes for which it was collected (as advised in this Privacy Policy);
						<br />
						<br />
						(ii)For the purposes stated in a privacy collection statement on a particularCollection Channel;
						<br />
						<br />
						(iii)To maintain the functionality of a Website, including the provision of information to you relating to the content available on the Website and e-commerce transactions conducted via the Website;
						<br />
						<br />
						(iv)To fulfil obligations in respect of any upload terms of use, sale and purchase contract and/or any other contract between you and us;
						<br />
						<br />
						(v)To send you any technical, administrative or legal notices important to ourWebsites;
						<br />
						<br />
						(vi)To provide you with information about your transactions (including uploading, access to, and receipt of content on a Website, and purchase transactions undertaken on a Website), content, services and products, to provide direct marketing materials, events, special offers, competitions and promotions in person and via any medium including mail, telephone and commercial electronic messages (SMS (Short Message Service), MMS (Multimedia Messaging Service), IM (Instant Messaging), FM (Facebook Message) and email) or any other form of electronic, emerging, digital or
						conventional communications channel;
						<br />
						<br />
						(vii) To maintain our relationship with you;
						<br />
						<br />
						(viii) To render services related to our company (such as enquiries);
						<br />
						<br />
						(ix) To provide you with newsletters via mail and electronic newsletters (e-newsletters) via commercial electronic messages;
						<br />
						<br />
						(x) To respond to customer enquiries and for complaint handling purposes;
						<br />
						<br />
						(xi) To improve Website and system administration;
						<br />
						<br />
						(xii) To obtain opinions or comments about products and/or services and to conduct other research and development;
						<br />
						<br />
						(xiii) To record statistical data for marketing analysis and to conduct market research;
						<br />
						<br />
						(xiv) To share personal information with our group companies, our promotional partners and other trusted third parties in the manner described below;
						<br />
						<br />
						(xv) To share personal information with third parties in order that they may provide you with direct marketing materials, events, special offers, competitions and promotions in person and via any medium including mail, telephone and commercial electronic messages (SMS (Short Message Service), MMS (Multimedia Messaging Service), IM (Instant Messaging), FM (Facebook Message) and email) or any other form of electronic, emerging, digital or conventional communications channel;
						<br />
						<br />
						(xvi) For the purposes described above, personal information may be shared with our group companies, our promotional partners and other trusted third parties including contractors (and their directors, servants and agents) either in Australia or overseas. The overseas locations where your personal information will be sent will depend upon the requirements of the third parties with whom we share personal information. Failure to provide personal information may result in us being unable to provide you with certain content, information, upcoming promotion, event or product
						information or being unable to provide your personal information to third parties for direct marketing purposes (where applicable).
					</p>

					<br />
					<br />
					<p className='h4 m0'>Sales of the Company</p>
					<p className='m0 op-50'>
						<br />
						(i) If we merge with, or are acquired by, another company, or sells all or a portion of its assets, your personal information may be disclosed to our advisers and any prospective purchaser’s adviser, and may be among the assets transferred. You have the right to access and review your personal information that may be recorded on our database. Information may be reviewed by contacting our privacy officer via email: admin@sieragroup.com.au.
						<br />
						<br />
						Please allow 30 days for this request to be processed We recognise the trust with which you provide personal information, and except as stated in this Privacy Policy, such information will not be used or disclosed for any other purposes without consent. However, we reserve the right to use or disclose any information, including personal information, as needed to satisfy any law, regulation or legal request, to protect the rights or property of us, any member of the our group, or any member of the public, to protect the integrity of a Website, to fulfil your requests, or
						to cooperate in any law enforcement investigation or an investigation on a matter of public safety.
						<br />
						<br />
					</p>

					<br />
					<p className='h4 m0'>Accuracy, completeness and timeliness of information</p>
					<p className='m0 op-50'>
						<br />
						The information on our website is not comprehensive and is intended to provide a summary of the subject matter covered. While we use all reasonable attempts to ensure the accuracy and completeness of the information on our website, to the extent permitted by law, including the Australian Consumer Law, we make no warranty regarding the information on this website. You should monitor any changes to the information contained on this website.
						<br />
						<br />
						We are not liable to you or anyone else if interference with or damage to your computer systems occurs in connection with the use of this website or a linked website. You must take your own precautions to ensure that whatever you select for your use from our website is free of viruses or anything else (such as worms or Trojan horses) that may interfere with or damage the operations of your computer systems.
						<br />
						<br />
						We may, from time to time and without notice, change or add to the website (including the Terms and Privacy Policy) or the information, products or services described in it. However, we do not undertake to keep the website updated. We are not liable to you or anyone else if errors occur in the information on the websiteor if that information is not up-to-date
					</p>
					<br />
					<br />
					<p className='h4 m0'>Linked sites</p>
					<p className='m0 op-50'>
						<br />
						Our website may contain links to websites operated by third parties. Those links are provided for convenience and may not remain current or be maintained. Unless expressly stated otherwise, we do not endorse and are not responsible for the content on those linked websites and have no control over or rights in those linked websites
					</p>
					<br />
					<br />
					<p className='h4 m0'>Intellectual property rights</p>

					<p className='m0 op-50'>
						<br />
						Unless otherwise indicated, we own or license from third parties all rights, title and interest (including copyright , designs, patents, trademarks and other intellectual property rights) in this website and in all of the material (including all text, graphics, logos, audio and software) made available on this website.
						<br />
						<br />
						Your use of this website anduse of and access to any Content does not grant or transfer any rights, title or interest to you in relation to this websiteor the Content. However we do grant you a licence to access the website and view the Content on the terms and conditions set out in this Agreement and, where applicable, as expressly authorised by us and/or our third party licensors.
						<br />
						<br />
						Any reproduction or redistribution of this website or the Content is prohibited and may result in civil and criminal penalties. In addition, you must not copy the Content to any other server, location or support for publication, reproduction or distribution is expressly prohibited.
						<br />
						<br />
						All other use, copying or reproduction of this website, the Content or any part of it is prohibited, except to the extent permitted by law.
					</p>
					<br />
					<br />
					<p className='h4 m0'>Unacceptable activity</p>
					<br />
					<p className='m0 op-50'>
						You must not do any act that we would deem to be inappropriate, is unlawful or is prohibited byany laws applicable to our website, including but not limited to:
						<br />
						<br />
						• any act that would constitute a breach of either the privacy (including uploading private or personal information without an individual's consent) or any other of the legal rights of individuals;
						<br />
						<br />
						• using this website to defame or libel us, our employees or other individuals;
						<br />
						<br />
						• uploading files that contain viruses that may cause damage to our property or the property of other individuals;
						<br />
						<br />
						• posting or transmitting to this website any non-authorised material including, but notlimited to, material that is, in our opinion likely to cause annoyance, or which is defamatory, racist, obscene, threatening, pornographic or otherwise or which is detrimental to or in violation of our systems or a third party's systems or network security.
						<br />
						<br />
						If we allow you to post any information to our Website or affiliated Social Medias, we have theright to take down this information at our sole discretionand without notice
					</p>
					<br />
					<br />

					<p className='h4 m0'> Warranties and disclaimers</p>
					<br />
					<p className='m0 op-50'>
						To the maximum extent permitted by law, including the Australian Consumer Law, we make no warranties or representations about this website or the Content, including but not limited to warranties or representations that they will be complete, accurate or up-to-date, that access will be uninterrupted or error-free or free from viruses, or that this website will be secure.
						<br />
						<br />
						We reserve the right to restrict, suspend or terminate without notice your access to this website, any Content, or any feature of this website at any time without notice and we will not be responsible for any loss, cost, damage or liability that may arise as a result.
					</p>
					<br />
					<br />
					<p className='h4 m0'>Liability</p>
					<br />
					<p className='m0 op-50'>To the maximum extent permitted by law, including the Australian Consumer Law, in no event shall we be liable for any direct and indirect loss, damage or expense – irrespective of themanner in which it occurs – which may be suffered due to your use of our website and/or the information or materials contained on it, or as a result of the inaccessibility of this website and/or the fact that certain information or materials contained on it are incorrect, incomplete or not up-to-date.</p>
					<br />
					<br />
					<p className='h4 m0'>Jurisdiction and governing law</p>
					<br />
					<p className='m0 op-50'>Your use of the website and these Terms and Privacy Policy are governed by the law of Queensland, Australia and you submit to the non-exclusive jurisdiction of the courts exercising jurisdiction in Queensland, Australia.</p>
				</div>
				<Spacer />
			</div>
		</Layout>
	)
}
